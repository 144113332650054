/* Estilos generales para la sección principal */
div {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Contenedor principal */
.Shop {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra los componentes horizontalmente */
    margin: 0 auto; /* Centra el contenedor */
}

/* Espaciado entre componentes */
.Hero,
.Popular,
.Offers,
.NewCollections,
.NewsLetter {
    width: 100%; /* Asegura que ocupen el ancho completo */
    max-width: 1200px; /* Ancho máximo del contenedor */
    margin: 20px 0; /* Espaciado vertical entre componentes */
    padding: 20px; /* Espaciado interno */
    border-radius: 8px; /* Bordes redondeados (opcional) */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra suave (opcional) */
}

/* Ajustes responsivos */
@media (max-width: 768px) {
    .Hero,
    .Popular,
    .Offers,
    .NewCollections,
    .NewsLetter {
        margin: 15px 0; /* Menos margen en dispositivos más pequeños */
        padding: 15px; /* Menos padding */
    }
}

@media (max-width: 480px) {
    .Hero,
    .Popular,
    .Offers,
    .NewCollections,
    .NewsLetter {
        margin: 10px 0; /* Aún menos margen en móviles */
        padding: 10px; /* Aún menos padding */
    }
}
