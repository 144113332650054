/* Niveles.css */

.niveles {
    padding: 20px;
}

.niveles h3{
    color: #c07777;
}

.image-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Ajusta el espacio entre las imágenes para dispositivos pequeños */
    justify-content: center; /* Centra las imágenes horizontalmente */
}

.image-gallery img {
    max-width: 250px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px; /* Borde redondeado para las imágenes */
    transition: transform 0.3s ease; /* Transición suave para la imagen */
}

.image-container {
    margin: 10px;
    text-align: center;
}

.image-container p {
    margin-top: 5px;
    font-size: 1rem;
    color: #333; 
}

.image-container img:hover {
    transform: scale(1.05); /* Efecto de escala al pasar el cursor sobre la imagen */
}

.image-container .hover-box-container {
    display: flex; /* Mostrar los cuadros grises en línea */
    justify-content: space-around; /* Espacio entre los cuadros grises */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 10px;
}

.hover-box {
    background-color: #ddd;
    color: #333;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 8px;
    opacity: 0; /* Inicialmente oculto */
    transition: opacity 0.3s ease;
}

.image-container:hover .hover-box {
    opacity: 1; /* Mostrar el cuadro hover al pasar el cursor sobre la imagen */
}

.hover-box.varon {
    background-color: #adb5bd; /* Gris oscuro para Varon */
}

.hover-box.mujer {
    background-color: #adb5bd; /* Gris claro para Mujer */
}

.location-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc; /* Línea alrededor del contenedor */
    border-radius: 10px; /* Bordes redondeados */
    padding: 10px; /* Espaciado interno */
    margin: 20px 0; /* Espaciado externo */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
    background-color: #86b1db; /* Color de fondo claro */
}

.location {
    font-size: 16px;
    color: #0a0a0a;
    text-align: center;
}


/* Media queries para dispositivos medianos */
@media (max-width: 768px) {
    .image-gallery {
        gap: 20px; /* Reducir el espacio entre imágenes */
    }

    .image-gallery img {
        max-width: 200px; /* Reducir el tamaño máximo de las imágenes */
    }

    .hover-box {
        padding: 8px 16px; /* Reducir el padding de los cuadros hover */
    }
}

/* Media queries para dispositivos pequeños */
@media (max-width: 480px) {
    .image-gallery {
        gap: 10px; /* Reducir aún más el espacio entre imágenes */
    }

    .image-gallery img {
        max-width: 150px; /* Reducir aún más el tamaño máximo de las imágenes */
    }

    .hover-box {
        padding: 6px 12px; /* Reducir aún más el padding de los cuadros hover */
    }

    .image-container p {
        font-size: 0.875rem; /* Reducir el tamaño de la fuente */
    }
}

