.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    max-width: 500px;
    width: 90%;
    position: relative;
  }

  .modal-content h3{
    text-align: center;
  }
  
  .modal-image {
    margin-top: 10px;
    max-width: 100%;
    height: 300px; /* Fixed height for uniform size */
    object-fit: cover; /* Ensure the image covers the specified height */
    border-radius: 8px;
    display: block; /* Makes the image a block element */
    margin-left: auto; /* Centers the image horizontally */
    margin-right: auto; /* Centers the image horizontally */
  }
  
  .modal-description {
    text-align: center; /* Centra el texto de la descripción */
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between; /* Ubica los botones en extremos opuestos */
    margin-top: 20px;
  }
  
  .quote-button, .close-button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .quote-button {
    background-color: #28a745;
    color: white;
  }
  
  .close-button {
    background-color: #dc3545;
    color: white;
  }
  
  