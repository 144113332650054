.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Estilos para el aviso de cookies */
.cookies-notice {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #1e1e1e; /* Color de fondo oscuro */
  color: #ffffff; /* Texto blanco */
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Se asegura de que esté encima de todo */
  font-size: 14px;
}

.cookies-notice p {
  margin: 0;
  line-height: 1.5;
}

.cookies-notice a {
  color: #0088ff; /* Azul para enlaces */
  font-weight: bold;
  text-decoration: underline;
}

.cookies-notice button {
  background-color: #ffffff; /* Fondo blanco */
  color: #000000; /* Texto negro */
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.cookies-notice button:hover {
  background-color: #f0f0f0;
}
