.newsletter {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 0px 20px;
    margin-bottom: 100px;
    background: linear-gradient(180deg, #6992dd 0%, #e1ffea22 60%);
    gap: 20px;
}

.newsletter h1 {
    color: #454545;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.newsletter p {
    color: #454545;
    font-size: 16px;
    text-align: center;
}

.newsletter div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 20px;
    border: 1px solid #e3e3e3;
    padding: 10px;
    gap: 10px;
}

.newsletter input {
    width: 90%;
    margin: 0;
    border: none;
    outline: none;
    color: #616161;
    font-family: Poppins;
    font-size: 14px;
    padding: 10px;
}

.newsletter div button {
    width: 90%;
    height: 40px;
    border-radius: 20px;
    background: black;
    color: white;
    font-size: 14px;
    cursor: pointer;
    padding: 10px;
}

@media (min-width: 768px) {
    .newsletter {
        flex-direction: column;
        gap: 30px;
        padding: 0px 40px;
        height: auto;
    }

    .newsletter h1 {
        font-size: 32px;
    }

    .newsletter p {
        font-size: 18px;
    }

    .newsletter div {
        flex-direction: row;
        width: 100%;
        max-width: 700px;
        height: 70px;
        padding: 0px;
        gap: 0px;
    }

    .newsletter input {
        width: 70%;
        font-size: 18px;
    }

    .newsletter div button {
        width: 30%;
        height: 100%;
        border-radius: 0px 20px 20px 0px;
        padding: 0px;
    }
}

@media (min-width: 1024px) {
    .newsletter {
        width: 65%;
        padding: 0px 140px;
        height: 40vh;
    }

    .newsletter h1 {
        font-size: 40px;
    }

    .newsletter p {
        font-size: 20px;
    }

    .newsletter div {
        width: 730px;
        height: 70px;
        border-radius: 80px;
        padding: 0px;
    }

    .newsletter input {
        width: 500px;
        margin-left: 30px;
        font-size: 20px;
    }

    .newsletter div button {
        width: 210px;
        height: 70px;
        border-radius: 80px;
        font-size: 16px;
    }
}

