/* Estilos generales */
.highlight {
    font-weight: bold;
}

.cartitems {
    margin: 20px auto;
    padding: 20px;
    max-width: 1200px;
}

/* Estilos para la tabla */
.cartitems table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.cartitems th,
.cartitems td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.cartitems th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
}

.cartitems tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.cartitems tbody tr:hover {
    background-color: #f1f1f1;
}

/* Estilos para los iconos */
.carticon-product-icon {
    height: 80px;
    width: auto;
}

.cartitems-remove-icon {
    width: 24px;
    cursor: pointer;
}

/* Estilos para la cantidad */
.pro-qty {
    font-size: medium;
    display: flex;
    align-items: center;
    justify-content: center;
}

.qtybtn {
    cursor: pointer;
    padding: 0 10px;
    background-color: #ddd;
    border: none;
    font-size: 18px;
    line-height: 1;
    display: inline-block;
    margin: 0 5px;
}

.qtybtn:hover {
    background-color: #bbb;
}

/* Estilos para la parte inferior */
.cartitems-down {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 40px 0;
}

.cartitems-delivery,
.cartitems-promocode,
.payment-method,
.cartitems-comments,
.cartitems-client-data {
    flex: 1;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #cac6c6;
    margin: 10px;
}

.cartitems-delivery p,
.cartitems-promocode p,
.payment-method,
.cartitems-comments,
.cartitems-client-data {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.cartitems-delivery label {
    display: block;
    margin-bottom: 10px;
}

.cartitems-delivery input[type='radio'] {
    margin-right: 10px;
}

/* Estilos para el formulario de código promocional */
.cartitems-promobox {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
    padding: 20px;
    height: auto;
    background: #cac6c6;
    border-radius: 8px;
}

.payment-method {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
    padding: 20px;
    height: auto;
    background: #cac6c6;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.payment-method input {
    width: auto;
    margin-right: 10px;
}

.cartitems-promobox input {
    border: 1px solid #ddd;
    outline: none;
    background: #f9f9f9;
    font-size: 16px;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
}

.cartitems-comments {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
    padding: 20px;
    height: auto;
    background-color: #cac6c6;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.cartitems-comments textarea {
    border: 1px solid #ddd;
    outline: none;
    background: #f9f9f9;
    font-size: 16px;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border-radius: 4px;
    resize: vertical;
    text-align: justify; /* Asegura que el texto esté justificado */
}

.cartitems-promobox-button {
    width: 100%;
    padding: 10px;
    outline: none;
    border: none;
    background: #47ca58;
    color: #070707;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 4px;
}

.cartitems-promobox-button:hover {
    background-color: #41fc51;
    transform: scale(1.02);
}

.cartitems-client-data {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 40px;
    max-width: 500px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    background: #e4dede;
}

.cartitems-client-data input {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    outline: none;
    background: #f9f9f9;
    font-size: 16px;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    width: 100%;
}

.cartitems-client-data textarea {
    border: 1px solid #ddd;
    outline: none;
    background: #f9f9f9;
    font-size: 16px;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border-radius: 4px;
    resize: vertical;
    text-align: justify; /* Asegura que el texto esté justificado */
}

.cartitems-client-data button {
    width: 100%;
    padding: 15px;
    outline: none;
    border: none;
    background: #477bca;
    color: #080808;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 8px;
}

.cartitems-client-data button:hover {
    background-color: #339dff;
    transform: scale(1.02);
}

.cartitems-client-data label {
    display: block;
    margin-bottom: 10px;
}

.cartitems-client-data h2 {
    margin-bottom: 10px;
}


/* Estilos para el total del carrito */
.cartitems-total {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 40px;
    max-width: 500px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    background: #e4dede;
}

.caritems-total-item {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
}

.cartitems-total button {
    width: 100%;
    padding: 15px;
    outline: none;
    border: none;
    background: #477bca;
    color: #080808;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 8px;
}

.cartitems-total button:hover {
    background-color: #339dff;
    transform: scale(1.02);
}

.cartitems-total hr {
    background-color: #ddd;
}


.hidden {
    display: none;
}

/* Estilo específico para botones deshabilitados */
button:disabled {
    color: gray;
    background-color: lightgray;
    border-color: gray;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    max-width: 500px;
    width: 90%;
    position: relative;
}

.modal-image {
    margin-top: 10px;
    max-width: 100%;
    height: 300px; /* Fixed height for uniform size */
    object-fit: cover; /* Ensure the image covers the specified height */
    border-radius: 8px;
    display: block; /* Makes the image a block element */
    margin-left: auto; /* Centers the image horizontally */
    margin-right: auto; /* Centers the image horizontally */
}

.modal-buttons {
    display: flex;
    justify-content: center; /* Ubica los botones en extremos opuestos */
    margin-top: 20px;
}

.close-button {
    background-color: #dc3545;
    color: white;
}

.talles-kits{
    font-size: small;
}
/* Media queries para responsividad */
@media (max-width: 768px) {
    .cartitems {
        padding: 12px;
    }

    .cartitems table,
    .cartitems th,
    .cartitems td {
        font-size: 14px;
        padding: 5px;
    }

    .qtybtn {
        padding: 0 5px;
        font-size: 16px;
    }

    .cartitems-delivery,
    .cartitems-promocode {
        margin: 5px 0;
        padding: 10px;
    }

    .cartitems-total {
        margin-top: 20px;
    }

    .cartitems-total button {
        padding: 8px;
    }
}

@media (max-width: 480px) {
    .cartitems {
        padding: 5px;
    }

    .cartitems table,
    .cartitems th,
    .cartitems td {
        font-size: 10px;
        padding: 2px;
    }

    .cartitems-remove-icon {
        width: 16px;
    }

    .carticon-product-icon {
        height: 50px;
    }

    .qtybtn {
        padding: 0 3px;
        font-size: 10px;
    }

    .cartitems-delivery,
    .cartitems-promocode {
        margin: 5px 0;
        padding: 5px;
    }

    .cartitems-total {
        margin-top: 10px;
    }

    .cartitems-total button {
        padding: 5px;
    }

    .hide-on-small {
        display: none;
    }
    .cartitems-down h3{
        font-size: 16px;
    }

    .payment-method-clarify{
        font-size: 12px;
    }

    .cartitems-down input{
        font-size: 10px;
    }

    .cartitems-down textarea{
        font-size: 12px;
        text-align: justify;
    }

    .cartitems-delivery input[type='radio'] {
        font-size: 10px
    }
    .talles-kits{
        font-size: 7px;
    }
}





