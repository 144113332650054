.contacto-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    flex-wrap: wrap; /* Permite que los elementos se ajusten en varias filas */
}

.column-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1200px; /* Ancho máximo para evitar que se estire demasiado */
    flex-wrap: wrap; /* Permite que las columnas se ajusten en varias filas si es necesario */
}

.info-cards {
    flex: 1;
    padding: 20px;
    margin-right: 20px; /* Espacio entre la columna de info y el mapa */
}

.info-cards .card {
    margin-bottom: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
}

/* Estilos para el contenedor del mapa y las tarjetas adicionales */
.map-and-cards {
    flex: 2;
    display: flex;
    flex-direction: column;
}

/* Estilo para el mapa */
.map-and-cards .leaflet-container {
    margin-bottom: 20px; /* Espacio entre el mapa y las tarjetas adicionales */
}

/* Estilos para las tarjetas adicionales */
.additional-cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
}

.additional-cards .card {
    margin-bottom: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
}

.custom-icon i {
    color: rgb(0, 81, 255); /* Cambia el color del ícono si es necesario */
    font-size: 24px; /* Ajusta el tamaño del ícono si es necesario */
}

.card:hover {
    transform: translateY(-5px);
}

/* Medios de pantalla (tablets y dispositivos más pequeños) */
@media (max-width: 768px) {
    .column-container {
        flex-direction: column; /* Cambia la dirección de las columnas a vertical */
    }

    .info-cards {
        margin-right: 0; /* Elimina el margen derecho en dispositivos pequeños */
        margin-bottom: 20px; /* Añade margen inferior para separación */
    }

    .map-and-cards {
        flex-direction: column; /* Cambia la dirección del contenido del mapa y tarjetas adicionales a vertical */
    }

    .map-and-cards .leaflet-container {
        height: 300px; /* Ajusta la altura del mapa para pantallas más pequeñas */
    }
}

/* Medios de pantalla (móviles) */
@media (max-width: 480px) {
    .info-cards .card {
        padding: 10px; /* Reduce el padding en tarjetas para dispositivos más pequeños */
    }

    .additional-cards .card {
        padding: 10px; /* Reduce el padding en tarjetas adicionales para dispositivos más pequeños */
    }
}
