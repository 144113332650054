.cartitems {
    padding: 20px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 100%; /* Ajusta según sea necesario */
}

.customer-payment-row {
    display: flex;
    gap: 20px;
}

.customer-form {
    flex: 2; /* 50% del espacio */
    min-width: 250px; /* Ajusta según sea necesario */
}

.cart-total {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 40px;
    max-width: 500px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    background: #a7a5a5;
}

.customer-form, .cart-items-list {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #e4dede;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
}

.customer-form h2, .cart-items-list h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.customer-form div{
    margin-bottom: 20px;
}

.customer-form label{
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.customer-form input{
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
}

.cart-total-p-text {
    font-size: 18px;
    color: #333;
    text-align: right;
}
.cart-total-p-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    text-align: center;
}

.customer-form {
    display: grid;
    gap: 10px; /* Reducir la distancia entre las filas */
}

.customer-form-row {
    display: flex;
    gap: 10px; /* Reducir la distancia entre los inputs en cada fila */
}

.customer-form-row > div {
    flex: 1;
}
.cart-total button {
    margin-top: 10px;
    width: 262px;
    height: 58px;
    outline: none;
    border: none;
    background: #477bca;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    align-self: center;
}
.cart-total button:hover {
    background-color: #339dff; /* Verde más oscuro */
    transform: scale(1.05);
}
/* Media Queries para dispositivos más pequeños */

@media (max-width: 768px) {
    .cartitems {
        margin: 50px 20px;
    }

    .cartitems-format-main {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .cartitems-promobox,
    .cartitems-total {
        width: 100%;
        margin: 0 auto;
    }

    .cartitems-promobox input {
        width: 100%;
    }

    .cartitems-promobox-button,
    .cartitems-total button {
        width: 100%;
    }

    .cartitems-down {
        flex-direction: column;
        gap: 20px;
    }

    .cartitems-delivery,
    .cartitems-promocode {
        margin-right: 0;
    }
}

@media (max-width: 480px) {
    .cartitems {
        margin: 20px 10px;
    }

    .cartitems-format-main {
        gap: 10px;
    }

    .cartitems-promobox {
        padding-left: 10px;
    }

    .cartitems-promobox input,
    .cartitems-promobox-button,
    .cartitems-total button {
        font-size: 14px;
        height: 45px;
    }

    .cartitems-total {
        gap: 20px;
        max-width: 100%;
    }
    .cart-total button {
        width: 150px;
        height: 48px;
    }

    .customer-form-row {
        flex-direction: column;
    }

    .customer-form-row > div {
        width: 100%;
    }
}