.new-collections {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    min-height: 100vh; /* Asegura que crezca según el contenido */
    padding: 20px; /* Espaciado interno */
    box-sizing: border-box; /* Asegura que el padding no afecte el tamaño */
}

.Schoolcategory-products {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Ajuste dinámico */
    gap: 20px; /* Espacio uniforme entre filas y columnas */
    width: 100%;
    max-width: 1200px; /* Ancho máximo del grid */
    margin: 0 auto; /* Centrado horizontal */
    box-sizing: border-box;
}

/* Encabezado */
.new-collections h1 {
    color: #171717;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
}

.new-collections hr {
    width: 150px;
    height: 4px;
    border-radius: 10px;
    background: #252525;
}

/* Media queries para ajustarse a pantallas más pequeñas */
@media (max-width: 1024px) {
    .Schoolcategory-products {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Ajuste dinámico */
        gap: 15px;
    }

    .new-collections h1 {
        font-size: 28px;
    }

    .new-collections hr {
        width: 120px;
        height: 3px;
    }
}

@media (max-width: 768px) {
    .Schoolcategory-products {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Ajuste dinámico */
        gap: 10px;
    }

    .new-collections h1 {
        font-size: 24px;
    }

    .new-collections hr {
        width: 100px;
    }
}

@media (max-width: 480px) {
    .Schoolcategory-products {
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Ajuste dinámico */
        gap: 8px;
    }

    .new-collections h1 {
        font-size: 20px;
    }

    .new-collections hr {
        width: 80px;
    }
}