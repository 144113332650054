.company-container {
    padding: 20px;
  }
  
  .products-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .product-card {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: calc(33.333% - 40px);
    box-sizing: border-box;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .product-card h3 {
    margin: 10px 0;
    font-size: 1.5em;
    color: #333;
  }
  
  .product-image {
    margin-top: 10px;
    max-width: 100%;
    height: 200px; /* Fixed height for uniform size */
    object-fit: cover; /* Ensure the image covers the specified height */
    border-radius: 8px;
  }
  
  @media (max-width: 768px) {
    .product-card {
      width: calc(50% - 20px); /* Adjust for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .product-card {
      width: 100%; /* Full width for very small screens */
    }
  }
  
  
  