.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 20px;
}

.footer-logo {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    text-align: center;
}

.footer-logo img{
    width: 70px;
}

.footer-social-icon img{
    width: 30px;
}

.footer-logo p {
    color: #383838;
    font-size: 46px;
    font-weight: 700;
}

.footer-links {
    display: flex;
    list-style: none;
    gap: 50px;
    color: #252525;
    font-size: 20px;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.footer-links li {
    cursor: pointer;
}

.footer-social-icon {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-icons-container {
    padding: 10px;
    padding-bottom: 6px;
    background: #fbfbfb;
    border: 1px solid #ebebeb;
}

.footer-copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: 100%;
    margin-bottom: 10px;
    color: #1a1a1a;
    font-size: 20px;
    text-align: center;
}

.footer-copyright hr {
    width: 80%;
    border: none;
    border-radius: 10px;
    height: 3px;
    background: #c7c7c7;
}
.atribution-p {
    display: block; /* Asegura que cada párrafo ocupe toda la línea */
    margin: 4px 0;  /* Controla el espaciado vertical entre los párrafos */
    font-size: 14px; /* Ajusta el tamaño de fuente si es necesario */
    color: #383838;  /* Color del texto */
    font-weight: 100; /* Estilo ligero */
}


.afip-icon-container img{
    width: 110px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .footer {
        gap: 30px;
    }

    .footer-logo p {
        font-size: 36px;
    }

    .footer-links {
        gap: 30px;
        font-size: 18px;
    }

    .footer-copyright {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .footer {
        gap: 20px;
    }

    .footer-logo {
        flex-direction: column;
        gap: 10px;
    }

    .footer-logo p {
        font-size: 28px;
    }

    .footer-links {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        font-size: 16px;
        justify-items: center;
    }
    

    .footer-social-icon {
        gap: 10px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        font-size: 16px;
        justify-items: center;
    }

    .footer-icons-container {
        padding: 8px;
        padding-bottom: 4px;
    }

    .footer-copyright {
        font-size: 16px;
        gap: 2px !important;
        padding: 2px !important;
    }
}

