@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

* {
    box-sizing: border-box;
}

.guide-bar {
    display: flex; /* Utiliza flexbox */
    align-items: center; /* Alinea los elementos verticalmente */
    white-space: nowrap; /* Evita que los elementos se rompan en varias líneas */
    overflow: hidden; /* Oculta cualquier contenido que sobrepase */
}

.guide-bar p {
    margin: 0; /* Elimina el margen por defecto de los párrafos */
    padding: 0 2px; /* Espaciado horizontal entre elementos */
}


body {
    margin: 0;
    padding: 0;
}

.gender {
    position: relative;
    background: #ffffff;
    padding-top: 20px; /* Añadir espacio superior */
    padding-left: 10px;
    padding-right: 10px;
}

.gender h3 {
    /*font-family: 'Press Start 2P', cursive;*/
    font-size: 15px;
    margin-bottom: 10px;
    color: #0c0c0c;
}

.product-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.product-gallery.darken {
    filter: brightness(50%);
}

.product-container {
    width: 280px; /* Ancho predeterminado */
    margin: 0 auto; /* Centra los elementos */
    text-align: center; /* Centra el texto */
    border: 1px solid #ddd; /* Borde claro alrededor de la tarjeta */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra sutil para dar efecto de profundidad */
    padding: 16px; /* Espaciado interno */
    background-color: #fff; /* Fondo blanco para la tarjeta */
    transition: transform 0.3s, box-shadow 0.3s; /* Transición suave para el hover */
}

.product-container:hover {
    transform: scale(1.05);
}

.product-name {
    font-size: 20px;
    color: #070707;
}

.product-container img {
    max-width: 70%;
    height: 250px;
    object-fit: cover;
}

.product-comment {
    margin-top: 8px;
    font-size: 14px;
    color: #555;
}

.product-price {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 20px;
    color: #202529;
}

.blink {
    animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
    50% {
        opacity: 0;
    }
}

.product-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 10px; /* Añadir padding para dispositivos móviles */
}

.product-modal-content {
    background: white;
    padding: 20px;
    border-radius: 4px;
    max-width: 600px;
    width: 100%;
    text-align: center;
    position: relative;
}

.product-modal-content img {
    max-width: 100%;
    height: auto;
    max-height: 250px;
    object-fit: cover;
}

.product-modal-content .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    color: #333;
}

.product-size-button {
    font-size: 15px;
    background-color: #5d8ac5; /* Fondo verde */
    border: none;
    color: white;
    padding: 10px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
    transition: background-color 0.3s, transform 0.2s;
}

.product-size-button:hover {
    background-color: #3b81db; /* Verde más oscuro */
    transform: scale(1.05);
}

.product-size-button:active {
    background-color: #3b81db; /* Verde aún más oscuro */
}

/* Estilo para el botón "Agregar al Carrito" */
button.add-to-cart {
    background-color: #0e160e; /* Verde */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s, transform 0.3s;
    /*font-family: 'Press Start 2P', cursive;*/
    margin-top: 20px;
}

button.add-to-cart:hover {
    background-color: #3b81db;
    transform: scale(1.05);
}

/* Estilo para el div que maneja las cantidades */

.product-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra horizontalmente el contenido */
}

.pro-qty {
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: center; /* Asegura que los elementos dentro de pro-qty estén centrados */
    
}

.pro-qty .qtybtn {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.3s;
    
}

.pro-qty .qtybtn:hover {
    background-color: #e0e0e0;
    transform: scale(1.1);
}

.pro-qty input {
    width: 50px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 0 10px;
    padding: 10px;
    font-size: 16px;
    pointer-events: none; /* Hacer que el input sea no editable */
    font-weight: 900;
}

.p-unidades {
    margin-top: 20px;
}

.location-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc; /* Línea alrededor del contenedor */
    border-radius: 10px; /* Bordes redondeados */
    padding: 10px; /* Espaciado interno */
    margin: 20px 0; /* Espaciado externo */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
    background-color: #86b1db; /* Color de fondo claro */
}

.location {
    font-size: 16px;
    color: #0a0a0a;
    text-align: center;
}

/* Media queries para dispositivos medianos y pequeños */
@media (max-width: 768px) {
    .product-container {
        flex: 1 1 calc(50% - 16px);
    }

    .product-modal-content {
        max-width: 100%;
        padding: 10px;
    }

    button.add-to-cart {
        padding: 10px 24px;
        font-size: 14px;
    }

    .product-size-button {
        padding: 8px 20px;
        font-size: 13px;
    }

    .pro-qty .qtybtn {
        padding: 8px;
        font-size: 14px;
    }

    .pro-qty input {
        width: 40px;
        padding: 8px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .product-container {
        flex: 1 1 100%;
    }

    .product-modal-content {
        max-width: 100%;
        padding: 10px;
    }

    button.add-to-cart {
        padding: 8px 16px;
        font-size: 12px;
    }

    .product-size-button {
        padding: 6px 16px;
        font-size: 12px;
    }

    .pro-qty .qtybtn {
        padding: 6px;
        font-size: 12px;
    }

    .pro-qty input {
        width: 30px;
        padding: 6px;
        font-size: 12px;
    }
}

