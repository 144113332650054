.school {
    width: 280px; /* Ancho predeterminado */
    margin: 0 auto; /* Centra los elementos */
    text-align: center; /* Centra el texto */
    border: 1px solid #ddd; /* Borde claro alrededor de la tarjeta */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra sutil para dar efecto de profundidad */
    padding: 16px; /* Espaciado interno */
    background-color: #fff; /* Fondo blanco para la tarjeta */
    transition: transform 0.3s, box-shadow 0.3s; /* Transición suave para el hover */
}

.school img {
    width: 100%; /* Asegura que la imagen ocupe todo el ancho del contenedor */
    max-width: 100px; /* Ancho máximo de la imagen */
    height: auto; /* Mantiene la proporción de la imagen */
    border-radius: 4px; /* Bordes redondeados para la imagen */
}

.school p {
    margin: 12px 0 0; /* Margen superior para separar del contenido superior */
    font-size: 16px; /* Tamaño de fuente predeterminado */
    color: #333; /* Color del texto */
}

/* Efecto de hover */
.school:hover {
    transform: scale(1.05); /* Aumenta ligeramente el tamaño de la tarjeta al pasar el ratón */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada al pasar el ratón */
}

/* Media queries para adaptarse a dispositivos medianos y pequeños */

/* Estilos para dispositivos medianos */
@media (max-width: 1024px) {
    .school {
        width: 220px; /* Ajusta el ancho del componente */
    }

    .school img {
        max-width: 80px; /* Ajusta el tamaño máximo de la imagen */
    }
}

/* Estilos para dispositivos pequeños */
@media (max-width: 768px) {
    .school {
        width: 180px; /* Ajusta el ancho del componente */
    }

    .school img {
        max-width: 60px; /* Ajusta el tamaño máximo de la imagen */
    }

    .school p {
        font-size: 14px; /* Ajusta el tamaño de la fuente */
    }
}

/* Estilos para dispositivos muy pequeños */
@media (max-width: 480px) {
    .school {
        width: 150px; /* Ajusta el ancho del componente */
    }

    .school img {
        max-width: 50px; /* Ajusta el tamaño máximo de la imagen */
    }

    .school p {
        font-size: 12px; /* Ajusta el tamaño de la fuente */
    }
}

