/* Contenedor principal */
.privacidad-container {
    max-width: 800px;
    margin: 30px auto;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  /* Títulos */
  .privacidad-title {
    text-align: center;
    color: #1e88e5;
    margin-bottom: 20px;
  }
  
  h2 {
    margin-top: 20px;
    font-size: 1.5em;
    color: #444;
  }
  
  h3 {
    margin-top: 10px;
    font-size: 1.2em;
    color: #555;
  }
  
  /* Párrafos */
  p {
    margin-bottom: 10px;
    text-align: justify;
  }
  
  /* Bloques destacados */
  blockquote {
    font-style: italic;
    background-color: #f9f9f9;
    border-left: 5px solid #1e88e5;
    padding: 10px 15px;
    margin: 10px 0;
    color: #555;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .privacidad-container {
      padding: 15px;
    }
  
    h2 {
      font-size: 1.3em;
    }
  
    h3 {
      font-size: 1.1em;
    }
  }
  