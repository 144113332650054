/* Loading.css */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75); /* Fondo oscuro semitransparente */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Asegura que esté por encima de todo */
  }
  
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff; /* Color del spinner */
    animation: spin 1s linear infinite; /* Animación */
  }
  
  @keyframes spin {
    to {
        transform: rotate(360deg);
    }
  }
  
  .loading-text {
    margin-top: 15px;
    color: #fff;
    font-size: 1.2rem;
    text-align: center;
  }
  
  .loading-logo {
    width: 150px; /* Ajusta el tamaño del logo según lo que necesites */
    margin-bottom: 20px; /* Espacio entre el logo y el spinner */
  }