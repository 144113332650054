.identidad-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  font-family: Arial, sans-serif;
}

.identidad-container p {
  margin-top: 5px;
  font-size: large;
  text-align: justify;
}

.identidad-image {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.identidad-image img {
  max-width: 80%;
  height: auto;
  border-radius: 10px;
}

.identidad-content {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.identidad-section {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.identidad-section h2 {
  margin-bottom: 10px;
  color: #333;
}

.identidad-section p {
  color: #666;
}

/* Media queries para dispositivos medianos */
@media (max-width: 768px) {
  .identidad-content {
    padding: 0 20px;
  }

  .identidad-section {
    padding: 15px;
  }

  .identidad-section h2 {
    font-size: 1.5em;
  }

  .identidad-section p {
    font-size: 1em;
  }
}

/* Media queries para dispositivos pequeños */
@media (max-width: 480px) {
  .identidad-container {
    margin: 10px;
  }

  .identidad-content {
    padding: 0 10px;
  }

  .identidad-section {
    padding: 10px;
  }

  .identidad-section h2 {
    font-size: 1.2em;
  }

  .identidad-section p {
    font-size: 0.9em;
  }
}

