.preguntas-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  
  .tarjeta {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .tarjeta h3 {
    margin-top: 0;
    font-size: 1.2em;
  }
  
  .tarjeta p {
    margin-bottom: 0;
  }
  
  .preguntas-container hr {
    margin-bottom: 10px;
    color: #6e6868;
  }
  
  .tarjeta:hover {
    transform: translateY(-5px);
  }
  
  /* Media Queries para dispositivos pequeños y medianos */
  @media (max-width: 768px) {
    .preguntas-container {
      padding: 10px;
    }
    
    .tarjeta {
      padding: 15px;
    }
  
    .tarjeta h3 {
      font-size: 1.1em;
    }
  
    .tarjeta p {
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 480px) {
    .preguntas-container {
      padding: 5px;
    }
  
    .tarjeta {
      padding: 10px;
    }
  
    .tarjeta h3 {
      font-size: 1em;
    }
  
    .tarjeta p {
      font-size: 0.8em;
    }
  }
  
  