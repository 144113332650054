.companies-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .buttons-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .catalog-button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
  }
  
  .catalog-button:hover {
    background-color: #0056b3;
  }
  
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 100%;
  }
  
  .company-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    flex: 1 1 calc(25% - 40px);
    max-width: calc(25% - 40px);
    box-sizing: border-box;
    border: 1px solid #ccc; /* Color gris para el contorno */
  }

  .company-card:hover {
    transform: scale(1.05); /* Aumenta ligeramente el tamaño de la tarjeta al pasar el ratón */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada al pasar el ratón */
  }

  
  
  .company-card h3 {
    margin: 0;
    font-size: 1.2em;
    color: #333;
  }
  
  .company-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .company-card {
      flex: 1 1 calc(50% - 40px);
      max-width: calc(50% - 40px);
    }
  }
  
  @media (max-width: 480px) {
    .company-card {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
  
  
  