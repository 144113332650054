/* Estilos globales */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif; /* Fuente común para todo el sitio */
}

/* Barra de guía */
.guide-bar {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
}

.guide-bar p {
    margin: 0;
    padding: 0 2px;
}

/* Contenedor de los kits */
.kits {
    position: relative;
    background: #ffffff;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.kits h3 {
    font-size: 15px;
    margin-bottom: 10px;
    color: #0c0c0c;
}

/* Galería de kits */
.kits-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center; /* Centrar los elementos en la galería */
}

/* Contenedor de cada kit */
.kit-container {
    min-width: 280px;
    max-width: 580px;
    margin: 0 auto;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    background-color: #fff;
    transition: transform 0.3s, box-shadow 0.3s;
}

.kit-container:hover {
    transform: scale(1.05);
}

/* Nombre del kit */
.kit-name {
    font-size: x-large;
    color: #070707;
}

/* Imagen del kit */
.kit-container img {
    width: 100%;  /* Asegura que la imagen ocupe el ancho del contenedor */
    max-width: 400px;  /* Limita el tamaño máximo para dispositivos grandes */
    height: auto;
    object-fit: cover;  /* Mantiene la proporción de la imagen */
}

/* Comentario debajo de los productos */
.kit-comment {
    margin-top: 8px;
    font-size: 14px;
    color: #555;
}

/* Tabla dentro del contenedor del kit */
.kit-container table {
    width: 100%; /* Asegura que la tabla ocupe todo el ancho disponible */
    table-layout: fixed; /* Mantén un tamaño de columna fijo */
    border-collapse: collapse; /* Elimina los espacios entre las celdas */
    margin-top: 10px; /* Espaciado superior */
}

.kit-container th, .kit-container td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
    font-size: 14px;
}

.kit-container th {
    background-color: #f9f9f9;
    font-weight: bold;
}

/* Imagen de información en la tabla */
.prod-information {
    max-width: 30px !important; /* Ajusta el tamaño de la imagen para que se vea bien en la tabla */
    height: 30px !important;
    display: block;
    margin: 0 auto;
}

.precio-kit{
    font-size: x-large;
}

/* Botón "Agregar al Carrito" */
button.add-to-cart {
    background-color: #0e160e;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s, transform 0.3s;
    margin-top: 20px;
}

button.add-to-cart:hover {
    background-color: #3b81db;
    transform: scale(1.05);
}

/* Sección de cantidad (Unidades) */
.p-unidades {
    margin-top: 20px;
}

.pro-qty {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.pro-qty .qtybtn {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.3s;
}

.pro-qty .qtybtn:hover {
    background-color: #e0e0e0;
    transform: scale(1.1);
}

.pro-qty input {
    width: 50px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 0 10px;
    padding: 10px;
    font-size: 16px;
    pointer-events: none;
    font-weight: 900;
}

.location-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc; /* Línea alrededor del contenedor */
    border-radius: 10px; /* Bordes redondeados */
    padding: 10px; /* Espaciado interno */
    margin: 20px 0; /* Espaciado externo */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
    background-color: #86b1db; /* Color de fondo claro */
}

.location {
    font-size: 16px;
    color: #0a0a0a;
    text-align: center;
}
/* Estilos responsivos */
@media (max-width: 768px) {
    /* Para tablets y dispositivos medianos */
    .kit-container {
        max-width: 100%; /* Elimina la restricción de ancho máximo */
        padding: 12px;
    }

    .kit-container img {
        max-width: 300px; /* Limita el tamaño de la imagen en pantallas más pequeñas */
    }

    .guide-bar {
        flex-direction: column; /* Los enlaces se mostrarán uno debajo del otro */
        align-items: flex-start; /* Alinea a la izquierda */
    }
}

@media (max-width: 480px) {
    /* Para dispositivos pequeños como teléfonos */
    .kit-container {
        padding: 10px;
        min-width: 180px;
        max-width: 340px;
    }

    .kits h3 {
        font-size: 13px;
    }

    .kit-container img {
        max-width: 100%; /* Haz que la imagen ocupe todo el ancho disponible */
    }

    button.add-to-cart {
        width: 100%; /* Asegura que el botón ocupe el ancho completo del contenedor en pantallas pequeñas */
    }

    .pro-qty input {
        width: 40px; /* Reduce el tamaño del input de cantidad en pantallas pequeñas */
    }

    .guide-bar {
        flex-direction: column;
        align-items: flex-start;
    }

    .kit-container table {
        font-size: 12px; /* Reduce el tamaño de fuente de la tabla en pantallas pequeñas */
    }

    .kit-container th, .kit-container td {
        padding: 6px;
    }
    .kit-container:hover {
        transform: scale(1.0);
    }
    .kit-container table select {
        font-size: 12px; /* Reduce el tamaño de la fuente */
        padding: 4px; /* Ajusta el padding interno del select */
        width: 100%; /* Asegura que el select ocupe todo el ancho de la celda */
    }
}