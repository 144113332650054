/* Estilos para la imagen del banner */
.Schoolcategory-banner {
    display: block;
    margin: 30px auto;
    width: 82%;
}

/* Estilos para el contenedor de índice y ordenamiento */
.Schoolcategory-indexSort {
    display: flex;
    margin: 0px 170px;
    justify-content: space-between;
    align-items: center;
}

/* Estilos para el contenedor de ordenamiento */
.Schoolcategory-sort {
    padding: 10px 20px;
    border-radius: 40px;
    border: 1px solid #888;
    display: flex;
    align-items: center;
}

/* Estilos para la etiqueta del desplegable */
.Schoolcategory-sort label {
    margin-right: 10px;
    font-size: 16px;
}

/* Estilos para el desplegable */
#school-dropdown {
    font-size: 14px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: auto;
    max-width: 200px;
    background-color: #fff;
    appearance: none;
}

/* Estilos para el texto del índice */
.Schoolcategory-indexSort p span {
    font-weight: 600;
}

/* Estilos para los productos */
.Schoolcategory-products {
    margin: 20px 170px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 80px;
}

/* Media query para dispositivos medianos y pequeños */
@media (max-width: 1024px) {
    .Schoolcategory-indexSort {
        margin: 0px 50px;
    }

    .Schoolcategory-products {
        margin: 20px 50px;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 40px;
    }

    .Schoolcategory-sort {
        padding: 8px 15px;
    }

    #school-dropdown {
        font-size: 12px;
        padding: 4px;
        max-width: 150px;
    }
}

@media (max-width: 768px) {
    .Schoolcategory-banner {
        width: 90%;
    }

    .Schoolcategory-indexSort {
        flex-direction: column;
        margin: 0px 20px;
    }

    .Schoolcategory-sort {
        width: 100%;
        margin-top: 10px;
    }

    .Schoolcategory-products {
        margin: 20px 20px;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 30px;
    }

    #school-dropdown {
        font-size: 12px;
        padding: 4px;
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .Schoolcategory-sort label {
        font-size: 14px;
    }

    #school-dropdown {
        font-size: 10px;
        padding: 3px;
    }
    #school-dropdown option{
        height: 100%;
    }

    .Schoolcategory-products {
        grid-template-columns: 1fr;
        row-gap: 20px;
    }
}


